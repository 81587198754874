export const USER_ROLES = {
    LEARNER: 'learner',
    EXTERNAL: 'external',
    STAFF_MEMBER: 'staff',
    SALES: 'sales',
    TEACHING_ASSISTANT: 'teaching_assistant',
    TRAINER: 'trainer',
    LEAD_INSTRUCTOR: 'lead_instructor',
    MANAGER: 'manager',
    ACCOUNT_MANAGER: 'account_manager',
    DIRECTOR: 'director',
    TECH_MEMBER: 'tech',
};

export const isStudentByRoles = (roles = []) => roles.length === 1 && roles[0] === USER_ROLES.LEARNER;

export const USER_STATES = {
    PROSPECT: 'prospect',
    REFUSED: 'refused',
    CANDIDATE: 'enrolled',
    MOTIVATION_INTERVIEW_DONE: 'pre_admitted',
    TECHNICAL_INTERVIEW_BOOKED: 'technical_interview_booked',
    MIGRATED: 'migrated',
    ADMITTED: 'admitted',
    IN_CREW: 'in_crew',
};
