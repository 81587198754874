import { endOfDay as _endOfDay, isValid, isWithinInterval as _isWithinInterval, parseISO } from 'date-fns';

export const minutesToHours = (mins: number): string => {
    const h = Math.floor(mins / 60);
    const m = mins % 60;
    const ms = m < 10 ? `0${m}` : m;

    const mf = `${ms}min${m > 1 ? 's' : ''}`;
    const hf = `${h}h`;

    if (h > 0) {

        if (m > 0) return `${hf} ${mf}`;

        return hf;
    }

    return mf;
};

export const checkDateRange = (start: string, end: string): boolean | string => {
    // - StartDate & EndDate are filled
    if (!start || !end || start === '' || end === '') {
        return 'errors.datesEmpty';
    }
    // - StartDate & EndDate are valid
    if (!isValid(new Date(start)) || !isValid(new Date(end))) {
        return 'errors.datesInvalid';
    }
    // - EndDate > StartDate
    if (new Date(start) > new Date(end)) {
        return 'errors.datesInvalid';
    }

    return true;
};

export const militaryHourToSeconds = (format: string): number => {
    if (!format) return 0;
    const segments = format.split(':');
    const [hours, minutes, seconds] = (() => {
        if (segments.length === 1) return [0, 0, parseInt(segments[0], 10)];
        if (segments.length === 2) return [0, parseInt(segments[0], 10), parseInt(segments[1], 10)];
        return [parseInt(segments[0], 10), parseInt(segments[1], 10), parseInt(segments[2], 10)];
    })();

    return seconds + (minutes * 60) + (hours * 3600);
};

export const floatingMinutesToHoursAndMinutes = (floatValue: number): object => {
    var hours = Math.floor(floatValue / 60);
    var minutes = Math.round((floatValue % 60));
    return { hours, minutes: String(minutes).padStart(2, '0') };
};

export const isWithinInterval = (date: string, start: string, end: string) => {
    return _isWithinInterval(parseISO(date), { start: parseISO(start), end: parseISO(end) });
};

type TypeRubyDateToJsIsoDateParams = {
    date: Date,
    endOfDay: boolean
}
type TypeRubyDateToJsIsoDateReturn = string | null
export const rubyDateToJsISODate = ({ date, endOfDay }: TypeRubyDateToJsIsoDateParams): TypeRubyDateToJsIsoDateReturn => {
    if (!date) return null;
    if (endOfDay) return _endOfDay(new Date(date)).toISOString()
    return new Date(date).toISOString();
};
