export const stripProtocol = (url: string) => url.replace('https://', '').replace('http://', '');

export const stripProtocolAndPort = (url: string) => url.replace('https://', '').replace('http://', '').replace(/:\d{1,}/, '');

export const getDomain = (url: string) => {
    if (url.includes('appspot.com')) return url;

    const [_, ...domain] = url.split('.');
    return domain.join('.');
}

export const getPrintableDomain = (url: string) => getDomain(url).replace('/', '')

export const getCurrentDomain = () => getDomain(window.location.hostname);
