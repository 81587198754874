import { getEnv } from '_config';

export const log = async (...data) => {
    const { debug } = getEnv()
    if (debug) console.log(...data); //eslint-disable-line
};

export const logError = (...data) => {
    console.error(data); //eslint-disable-line
};

export const logWarning = (...data) => {
    const { debug } = getEnv()
    if (debug) console.warn(data); //eslint-disable-line
};
